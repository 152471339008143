import http from "./httpService";
import config from "../config.json";

const { notiApiUrl } = config;

const apiEndPoint = notiApiUrl + "api/notification/";

export function createOrEditMemberBrowserToken(data) {
  return http.post(apiEndPoint + "createOrEditMemberBrowserToken", data);
}
