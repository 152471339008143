import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faSearch } from "@fortawesome/free-solid-svg-icons";
import OffCanvas from "react-aria-offcanvas";
import { NotificationManager } from "react-notifications";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { InlineIcon } from "@iconify/react";
import PromoIcon from "@iconify/icons-mdi/discount-circle";
import userOutlineIcon from "@iconify/icons-mdi/user-outline";
import usersOutlineIcon from "@iconify/icons-mdi/users-outline";
import facebookIcon from "@iconify/icons-mdi/facebook";
import googleIcon from "@iconify/icons-mdi/google";
import instagramIcon from "@iconify/icons-mdi/instagram";
import twitterIcon from "@iconify/icons-mdi/twitter";
import chevronRightIcon from "@iconify/icons-mdi/chevron-double-right";
import arrowRightIcon from "@iconify/icons-mdi/arrow-right";
import arrowLeftIcon from "@iconify/icons-mdi/arrow-left";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Particles from "react-tsparticles";
//import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.

import CommonContext from "../../context/CommonContext";
// Services
import { getNearbyShop } from "../shop/shopService";
import {
  getLocalStorageCurrentUserName,
  getLocalStorageUserLocation,
} from "../../services/localStorage";
import { getDiscount } from "../../services/discountService";
import { getToken } from "../auth/authService";
// Components
import { Image, Loading } from "../common";
// Assets
import Logo from "../../assets/img/yoyo_logo.png";
import AppleAppImg from "../../assets/img/d-apple.png";
import GoogleAppImg from "../../assets/img/d-google.png";
import Shops from "../shop/shop";

// app functions
import coffeeBreak from "../../assets/landing/coffee-break.png";
import orderPickup from "../../assets/landing/order-pickup.png";
import orderDeli from "../../assets/landing/order-delivery.png";
import orderReserve from "../../assets/landing/order-reserve.png";
import orderPOS from "../../assets/landing/order-pos.png";
// import { getLocation } from "../../utils/location";
import menuLogo from "../../assets/img/yoyo_logo_white.png";
import icon1 from "../../assets/landing/Icon-1.png";
import icon2 from "../../assets/landing/Icon-2.png";
import icon3 from "../../assets/landing/Icon-3.png";
import icon4 from "../../assets/landing/Icon-4.png";
import icon5 from "../../assets/landing/Icon-5.png";
import icon6 from "../../assets/landing/Icon-6.png";
import icon7 from "../../assets/landing/Icon-7.png";
import icon9 from "../../assets/landing/Icon-8.png";
import icon10 from "../../assets/landing/Icon-9.png";
import icon11 from "../../assets/landing/Icon-10.png";
import icon12 from "../../assets/landing/Icon-11.png";
import icon13 from "../../assets/landing/Icon-12.png";
import icon15 from "../../assets/landing/Icon-13.png";
import icon18 from "../../assets/landing/Icon-14.png";
import screenShot1 from "../../assets/landing/app-screen-short.png";
import screenShot2 from "../../assets/landing/app-screen-short-2.png";
import screenShot3 from "../../assets/landing/app-screen-short-3.png";
import appSlideImg1 from "../../assets/landing/screenshort-slider-img-1.png";
import appSlideImg2 from "../../assets/landing/screenshort-slider-img-2.png";
import appSlideImg3 from "../../assets/landing/screenshort-slider-img-3.png";
import appSlideImg4 from "../../assets/landing/screenshort-slider-img-4.png";
import appSlideImg5 from "../../assets/landing/screenshort-slider-img-5.png";

// CSS
import "../style/promotion.css";
import "../style/landing.css";

const offCanvasMenuStyles = {
  content: {
    background: "rgb(255,255,255)",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.25)",
    zIndex: "99999",
  },
  menuButton: {
    outline: "none",
    border: "none",
    backgroundColor: "transparent",
    fontSize: "23px",
    padding: 0,
    marginTop: -4,
  },
  icon: {
    color: "red",
  },
  menuBtnIcon: {
    color: "#fff",
    fontSize: 18,
  },
  menuButtonClose: {
    outline: "none",
    border: "none",
    backgroundColor: "transparent",
    color: "#676767",
    fontWeight: "400",
    position: "absolute",
    top: 3,
    right: 0,
    outline: "none",
    border: "none",
    backgroundColor: "transparent",
    padding: 12,
    fontSize: 18,
  },
  OffCanvasMenuList: {
    listStyle: "none",
    paddingLeft: 0,
  },
};

export default function Landing() {
  const canvasRef = useRef(null);

  let token = getToken();
  const navigate = useNavigate();
  const userName = getLocalStorageCurrentUserName();

  const commonContext = useContext(CommonContext);
  const { languageData } = commonContext;

  const [shops, setShops] = useState({
    isLoad: true,
    data: [],
  });
  const [discounts, setDiscounts] = useState({
    isLoad: true,
    data: [],
  });
  const [isOpen, setIsOpen] = useState(false);

  const [stickyMenu, setStickyMenu] = useState(false);
  const [position, setPosition] = useState([]);
  const [tempShopName, setTempShopName] = useState("");
  const [shopName, setShopName] = useState("");

  const close = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    (async () => {
      if (token) {
        navigate("/home");
      } else {
        // getLocation();
        loadShops();
        loadDiscounts();
      }
    })();
  }, []);

  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    window.addEventListener("scroll", isScroll);
    return () => {
      window.removeEventListener("scroll", isSticky);
      window.removeEventListener("scroll", isScroll);
    };
  });

  // $("body").on("mousemove", function (e) {
  //   //   var x = e.pageX,
  //   //       y = e.pageY,
  //   //       w = $(window).width()/2;

  //   //   if(x > w){
  //   //     var dir = "right"
  //   //     } else if(x < w){
  //   //       var dir = "left"
  //   //       }

  //   //   console.log(dir);

  // });

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const header = document.querySelector(".main-menu-area");
    const scrollTop = window.scrollY;
    scrollTop >= 50
      ? header.classList.add("sticky-menu")
      : header.classList.remove("sticky-menu");
    if (scrollTop >= 50) {
      setStickyMenu(true);
    } else {
      setStickyMenu(false);
    }
  };

  const isScroll = () => {
    const scrollTop = window.scrollY;
    document.querySelectorAll(".scrollable").forEach((feature) => {
      feature.classList.toggle(
        "scrollable-before",
        scrollTop > 0 && scrollTop <= 1300
      );
      feature.classList.toggle(
        "scrollable-before-and-between",
        scrollTop > 1300 && scrollTop <= 1400
      );
      feature.classList.toggle(
        "scrollable-between",
        scrollTop > 1400 && scrollTop <= 1500
      );
      feature.classList.toggle(
        "scrollable-between-and-after",
        scrollTop > 1500 && scrollTop <= 1600
      );
      feature.classList.toggle("scrollable-after", scrollTop > 1600);
    });
  };

  window.addEventListener("scroll", isScroll);

  const loadShops = async () => {
    setShops({ ...shops, isLoad: true });
    const center = getLocalStorageUserLocation();

    try {
      const config = {
        params: {
          page: 1,
          pageSize: 20,
          lat: center.lat,
          lng: center.lng,
          name: "",
        },
      };
      const { data } = await getNearbyShop(config);

      let tShops = { ...shops };
      if (data.data) {
        tShops.data = data.data;
      }

      setShops({ ...tShops, isLoad: false });
    } catch (error) {
      NotificationManager.error(error, languageData.serverError);
    }
  };

  const loadDiscounts = async () => {
    setDiscounts({ ...discounts, isLoad: true });

    try {
      const config = {
        params: {
          page: 1,
          pageSize: 20,
          sortColumn: "id",
          sortOrder: "desc",
        },
      };
      const { data } = await getDiscount(config);

      let tDiscounts = { ...discounts };
      if (data.data) {
        tDiscounts.data = data.data;

        tDiscounts.data = tDiscounts.data.filter(
          (item) => item.bannerImage !== null && item.bannerImage !== ""
        );
      }
      setDiscounts({ ...tDiscounts, isLoad: false });
    } catch (error) {
      NotificationManager.error(error, languageData.serverError);
    }
  };

  const options = {
    autoplay: false,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: true,
    navText: [
      "<div class='nav-button owl-prev'></div>",
      "<div class='nav-button owl-next'></div>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 3,
      },
      991: {
        items: 4,
      },
      1024: {
        items: 5,
      },
    },
  };

  const optionsOne = {
    autoplay: false,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: true,
    navText: [
      "<div class='nav-button owl-prev'></div>",
      "<div class='nav-button owl-next'></div>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      991: {
        items: 3,
      },
      1024: {
        items: 3,
      },
    },
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    setTempShopName(e.target.value);
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    // console.log(tempShopName);
    // setShopName(tempShopName);
    navigate("/search", {
      state: {
        shopName: tempShopName,
      },
    });
  };

  const RenderNearByShop = () => {
    if (shops.isLoad) return <Loading isLoad={shops.isLoad} />;
    if (shops.data.length > 0)
      return (
        <div className="section-padding mt-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                {/* Nearby Shop Holder  */}
                <div className="nearby-shop-wrapper">
                  <div className="d-flex flex-row justify-content-between align-items-center mb-2">
                    <h5 className="section-title mb-0">
                      {languageData.shopNearByMe}
                    </h5>
                    <Link
                      to="/shopList"
                      state={{ title: languageData.shopNearByMe, nearBy: true }}
                      className="view-more"
                    >
                      {languageData.viewMore}
                      <InlineIcon icon={chevronRightIcon} className="ms-2" />
                    </Link>
                  </div>
                  {/* Web Ui */}
                  <div className="d-none d-xl-block d-lg-block d-md-block d-sm-none carousel-wrapper">
                    <OwlCarousel
                      className="shop-list-wrap owl-theme"
                      {...options}
                    >
                      {shops.data.map((item) => (
                        <Link
                          to={`/productList/${item.route}`}
                          key={item.id}
                          className="shop-link"
                        >
                          <div className="shop--content">
                            <div className="shop-img-holder">
                              <Image
                                name={item.logo}
                                className="shop-img"
                                alt="Item Image"
                              />
                              <div className="shop-name-holder">
                                <div className="shop-name">
                                  <span>{item.name}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </OwlCarousel>
                  </div>

                  {/* Mobile Ui */}
                  <div className="d-block d-xl-none d-lg-none d-md-none d-sm-block">
                    <ScrollContainer className="d-flex flex-row shop-list-wrap">
                      {shops.data.map((item) => (
                        <Link
                          to={`/productList/${item.route}`}
                          key={item.id}
                          className="shop-link"
                        >
                          <div className="shop--content">
                            <div className="shop-img-holder">
                              <Image
                                name={item.logo}
                                className="shop-img"
                                alt="Item Image"
                              />
                              <div className="shop-name-holder">
                                <div className="shop-name">
                                  <span>{item.name}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </ScrollContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  };

  const RenderDiscount = () => {
    if (discounts.isLoad) return <Loading isLoad={discounts.isLoad} />;
    if (discounts.data.length > 0)
      return (
        <div className="promotion-info section-padding">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                {/* Nearby Shop Holder  */}
                <div className="promotion-list-wrap">
                  <div className="row justify-content-center mb-5">
                    <div className="section-heading">
                      <h2>{languageData.dicountForYou}</h2>
                    </div>
                  </div>

                  {/* Web Ui */}
                  <div className="d-none d-xl-block d-lg-block d-md-block d-sm-none">
                    <OwlCarousel
                      className="promotion_wrapper owl-theme"
                      {...optionsOne}
                    >
                      {discounts.data.map((item) => (
                        <Link
                          to={`/productList/${item.shopRoute}`}
                          state={{
                            discountId: item.id,
                          }}
                          key={item.id}
                          className="promotion-item_link"
                        >
                          <div className="promotion-item_holder">
                            <Image
                              name={item.bannerImage}
                              className="promotion-img"
                              alt="Item Image"
                              banner
                            />
                            {/* <p className="promo-title">{item.title}</p> */}
                          </div>
                          <div className="promo-title-area">
                            <InlineIcon
                              icon={PromoIcon}
                              className="promo-icon"
                            />
                            <p className="promo-title">{item.title}</p>
                          </div>
                        </Link>
                      ))}
                    </OwlCarousel>
                  </div>
                  {/* Mobile Ui */}
                  <div className="d-block d-xl-none d-lg-none d-md-none d-sm-block">
                    <ScrollContainer className="d-flex flex-row ">
                      {discounts.data.map((item) => (
                        <Link
                          to={`/productList/${item.shopRoute}`}
                          state={{
                            discountId: item.id,
                          }}
                          key={item.id}
                          className="promotion-item_link"
                        >
                          <div className="promotion-item_holder">
                            <Image
                              name={item.bannerImage}
                              className="promotion-img"
                              alt="Item Image"
                              banner
                            />
                            <p className="promo-title">{item.title}</p>
                          </div>
                        </Link>
                      ))}
                    </ScrollContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  };
  const [rotationAngle, setRotationAngle] = useState(0);

  const handleChangeCarousel = (increment) => {
    setRotationAngle(rotationAngle - increment);
  };

  const particlesInit = useCallback(async (engine) => {
    await loadSlim(engine);
  }, []);

  return (
    <>
      <div className="landing-wrap">
        {/* Header */}
        <header class="main-menu-area">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-md-3 col-sm-3 col-xs-6">
                <div className="navbar-brand d-flex">
                  <button
                    aria-label="Menu"
                    aria-controls="menu"
                    aria-expanded={isOpen}
                    onClick={() => setIsOpen(true)}
                    style={offCanvasMenuStyles.menuButton}
                    className="d-block d-lg-none d-md-none d-sm-block"
                  >
                    {stickyMenu ? (
                      <FontAwesomeIcon icon={faBars} className="menu-icon" />
                    ) : (
                      <FontAwesomeIcon
                        icon={faBars}
                        className="menu-icon text-white"
                      />
                    )}
                  </button>

                  <Link to="/">
                    <img
                      src={stickyMenu ? Logo : menuLogo}
                      className="logo-img"
                      alt="Logo Image"
                    />
                  </Link>
                </div>
              </div>

              <div class="col-md-9 col-sm-9 col-xs-6 d-none d-xl-block d-lg-block d-md-block d-sm-none">
                <nav class="landing-page-menu">
                  <ul>
                    <li>
                      {token ? (
                        <Link to="/userProfile">
                          <span>{userName}</span>
                        </Link>
                      ) : (
                        <Link to="/login">
                          <span>{languageData.loginOrSingUp}</span>
                        </Link>
                      )}
                    </li>
                    <li>
                      <Link
                        className="nav-link partner-button"
                        to="https://www.12zay.com/InstaPOSAdmin/registerShop"
                        target="_blank"
                      >
                        <span>{languageData.partnerWithUs}</span>
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </header>
        <section class="landing-welcome-wrap welcome-section">
          {/* Welcome */}
          <div class="landing-welcome-container">
            <div class="container">
              <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <div class="welcome-tbl">
                    <div class="welcome-tbl-c">
                      <div class="welcome-content text-left">
                        <p className="small-text">
                          Pick-up yourself or orders delivered at your door,{" "}
                          <br /> make a reservation for a dinner?
                        </p>
                        <h1>
                          A brand new
                          <br /> ordering system
                          <br /> on local restaurants
                        </h1>
                        <div className="download-wrap">
                          <div className="d-flex flex-row">
                            <Link to="/home" className="me-3">
                              <img
                                src={AppleAppImg}
                                className="download-app"
                                alt="Download Image"
                              />
                            </Link>
                            <Link to="/home">
                              <img
                                src={GoogleAppImg}
                                className="download-app"
                                alt="Download Image"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div className="welcome-tbl">
                    <div className="welcome-tbl-c">
                      <div className="welcome-slide">
                        <Carousel autoPlay={true} infiniteLoop={true}>
                          <div>
                            <img
                              src={screenShot1}
                              alt=""
                              style={{ width: "250px" }}
                            />
                          </div>
                          <div>
                            <img
                              src={screenShot2}
                              alt=""
                              style={{ width: "250px" }}
                            />
                          </div>
                          <div>
                            <img
                              src={screenShot3}
                              alt=""
                              style={{ width: "250px" }}
                            />
                          </div>
                        </Carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Particles
          id="tsparticles"
          init={particlesInit}
          options={{
            // background: {
            //   color: {
            //     value: "#0d47a1",
            //   },
            // },
            fullScreen: {
              enable: false,
            },
            fpsLimit: 20,
            interactivity: {
              modes: {
                push: {
                  quantity: 20,
                },
                repulse: {
                  distance: 300,
                  duration: 0.3,
                },
              },
            },
            particles: {
              number: {
                value: 100,
              },
              color: {
                value: "#ffffff",
              },
              move: {
                direction: "none",
                enable: true,
                outModes: {
                  default: "bounce",
                },
                random: false,
                speed: 2,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 800,
                },
                value: 200,
              },
              opacity: {
                value: 0.3,
              },
              shape: {
                type: "circle",
              },
              size: {
                animation: {
                  enable: false,
                  minimumValue: 3,
                  speed: 10,
                  sync: false,
                },
                random: true,
                value: 3,
              },
            },
            detectRetina: true,
          }}
        />
        <section className="nearby-shop-section mb-0  pb-4">
          <div className="container shop-list_wrapper">
            <div className="row mb-2">
              <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12">
                <h3 className="landing-main-title">
                  Order now your favourite foods!
                </h3>
                <p className="small-text">
                  Choose any options you want to : Pick up at the shop,
                  Delivered at home or Reservation.
                </p>
                <div className="search-wrap mt-4">
                  <div className="row gx-3">
                    <div className="col-9">
                      <input
                        type="text"
                        placeholder={"Eg. KFC, YKKO, Lotteria"}
                        className="form-control search-input mb-0"
                        onChange={handleInputChange}
                        value={tempShopName}
                      />
                    </div>
                    <div className="col-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={handleButtonClick}
                      >
                        <FontAwesomeIcon
                          icon={faSearch}
                          className="search-icon"
                        />
                        <div className="d-none d-xl-block d-lg-block d-md-block d-sm-none ms-3">
                          <span>{languageData.search}</span>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <img src={coffeeBreak} alt="Pick Up" className="search-img" />
              </div>
            </div>
          </div>
        </section>
        <section className="section-white">
          <Shops
            title={languageData.nearbyShops}
            nearBy={true}
            code="shop"
            shopName={shopName}
          />
          <div className="section-divider"></div>
          <Shops
            title={languageData.nearbyRestaurants}
            nearBy={true}
            code="restaurant"
            shopName={shopName}
          />
        </section>
        {/* <section className="nearby-shop-section">
          {RenderNearByShop()}
        </section> */}
        <section>
          {/* HEADER STARTS */}
          <div className="section-top-bg">
            {/* <header>
            <nav className="navbar navbar-expand-lg navbar-header">
              <div className="container">
                <div className="navbar-brand d-flex">
                  <button
                    aria-label="Menu"
                    aria-controls="menu"
                    aria-expanded={isOpen}
                    onClick={() => setIsOpen(true)}
                    style={offCanvasMenuStyles.menuButton}
                    className="d-block d-lg-none d-md-none d-sm-block"
                  >
                    <FontAwesomeIcon icon={faBars} className="menu-icon" />
                  </button>

                  <Link to="/home">
                    <img src={Logo} className="logo-img" alt="Logo Image" />
                  </Link>
                </div>
                <ul className="header-right ms-auto">
                  <li className="nav-item">
                    {token ? (
                      <Link className="nav-link me-3" to="/userProfile">
                        <span>{userName}</span>
                      </Link>
                    ) : (
                      <Link className="nav-link me-3" to="/login">
                        <span>{languageData.loginOrSingUp} </span>
                      </Link>
                    )}
                  </li>

                  <li className="nav-item ">
                    <Link
                      className="nav-link partner-button"
                      to="https://www.12zay.com/InstaPOSAdmin/registerShop"
                      target="_blank"
                    >
                      <span>{languageData.partnerWithUs}</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </header>
          <div className="banner-section">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-6">
                  <p className="small-text">
                    Pick-up yourself or orders delivered at your door, <br />{" "}
                    make a reservation for a dinner?
                  </p>
                  <h1>
                    A brand new <br /> ordering system <br /> for local
                    restaurants
                  </h1>
                  <div className="download-wrap">
                    <div className="d-flex flex-row">
                      <Link to="/home" className="me-3">
                        <img
                          src={AppleAppImg}
                          className="download-app"
                          alt="Download Image"
                        />
                      </Link>
                      <Link to="/home">
                        <img
                          src={GoogleAppImg}
                          className="download-app"
                          alt="Download Image"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="banner-element">
                    <img
                      src={BannerElement}
                      className="banner-element-img"
                      alt="Logo Image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
            {/* <img src={RectangleImg} className="banner-bg-img" alt="Logo Image" /> */}
          </div>
          {/* Banner Section */}

          {/* Discount */}
          {RenderDiscount()}
        </section>
        <section className="our-functions feature-section section-padding">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-4">
                <div className="section-heading">
                  <h2>Features</h2>
                </div>
              </div>
            </div>
            <div className="row content-margin-top">
              <div className="col-xl-6 col-md-12">
                <div class="feature-img text-center">
                  <figure>
                    <img
                      src={screenShot1}
                      className="screen-shot-img"
                      alt="Feature Image"
                    />

                    <span class="feature-icon icon-1x scrollable">
                      <img src={icon1} alt="Small Icon" />
                    </span>

                    <span class="feature-icon icon-2x scrollable">
                      <img src={icon2} alt="Small Icon" />
                    </span>

                    <span class="feature-icon icon-3x scrollable">
                      <img src={icon12} alt="Small Icon" />
                    </span>

                    <span class="feature-icon icon-4x scrollable">
                      <img src={icon5} alt="Small Icon" />
                    </span>

                    <span class="feature-icon icon-5x scrollable">
                      <img src={icon7} alt="Small Icon" />
                    </span>

                    <span class="feature-icon icon-6x scrollable">
                      <img src={icon15} alt="Small Icon" />
                    </span>

                    <span class="feature-icon icon-7x scrollable">
                      <img src={icon3} alt="Small Icon" />
                    </span>

                    <span class="feature-icon icon-8x scrollable">
                      <img src={icon9} alt="Small Icon" />
                    </span>

                    <span class="feature-icon icon-9x scrollable">
                      <img src={icon11} alt="Small Icon" />
                    </span>

                    <span class="feature-icon icon-10x scrollable">
                      <img src={icon13} alt="Small Icon" />
                    </span>

                    <span class="feature-icon icon-11x scrollable">
                      <img src={icon4} alt="Small Icon" />
                    </span>

                    <span class="feature-icon icon-12x scrollable">
                      <img src={icon10} alt="Small Icon" />
                    </span>

                    <span class="feature-icon icon-13x scrollable">
                      <img src={icon6} alt="Small Icon" />
                    </span>

                    <span class="feature-icon icon-14x scrollable">
                      <img src={icon15} alt="Small Icon" />
                    </span>

                    <span class="feature-icon icon-15x scrollable">
                      <img src={icon13} alt="Small Icon" />
                    </span>

                    <span class="feature-icon icon-16x scrollable">
                      <img src={icon18} alt="Small Icon" />
                    </span>
                  </figure>
                </div>
              </div>
              <div class="col-xl-6 col-md-12">
                <div class="single-feature">
                  <div class="feature-content">
                    <img src={orderPickup} alt="Pick Up" />
                    <h3 className="title">Pick Up</h3>
                    <p className="para">
                      Order from online and pick-up at the shop or a restaurant
                      by the time your prefer.
                    </p>
                  </div>
                </div>
                <div class="single-feature">
                  <div class="feature-content " data-wow-delay="400ms">
                    <img src={orderDeli} alt="Delivery" />
                    <h3 className="title">Delivery</h3>
                    <p className="para">
                      Receive orders at your doorstep through easy and reliable
                      online payments or cash.
                    </p>
                  </div>
                </div>
                <div class="single-feature">
                  <div class="feature-content">
                    <img src={orderReserve} alt="Reserve" />
                    <h3 className="title">Reserve</h3>
                    <p className="para">
                      Make a reservation for a dinner or events of your precious
                      moments using Axtra.
                    </p>
                  </div>
                </div>
                <div class="single-feature">
                  <div class="feature-content">
                    <img src={orderPOS} alt="POS" />
                    <h3 className="title">POS</h3>
                    <p className="para">
                      Scan QR code, sit back and relax, make an order by
                      yourself. Fresh cool ordering system for you!
                    </p>
                  </div>
                </div>
              </div>

              {/* <div className="col-md-6">
                <div className="row info-section align-items-center">
                  <div className="col-6 mt-4">
                    <div className="feature-content">
                      <img src={orderPickup} alt="Pick Up" />
                      <h4 className="title">Pick Up</h4>
                      <p className="para">
                        Order from online and pick-up at the shop or a
                        restaurant by the time your prefer.
                      </p>
                    </div>
                  </div>
                  <div className="col-6 mt-4">
                    <div className="feature-content">
                      <img src={orderDeli} alt="Delivery" />
                      <h4 className="title">Delivery</h4>
                      <p className="para">
                        Receive orders at your doorstep through easy and
                        reliable online payments or cash.
                      </p>
                    </div>
                  </div>
                  <div className="col-6 mt-4">
                    <div className="feature-content">
                      <img src={orderReserve} alt="Reserve" />
                      <h4 className="title">Reserve</h4>
                      <p className="para">
                        Make a reservation for a dinner or events of your
                        precious moments using YoYo.
                      </p>
                    </div>
                  </div>
                  <div className="col-6 mt-4">
                    <div className="feature-content">
                      <img src={orderPOS} alt="POS" />
                      <h4 className="title">POS</h4>
                      <p className="para">
                        Scan QR code, sit back and relax, make an order by
                        yourself. Fresh cool ordering system for you!
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        <section class="app-screen-section section-padding">
          <div class="container">
            <div class="section-heading">
              <h2>App Screen</h2>
            </div>

            <div class="content-margin-top content-margin-bottom">
              <div class="app-screen-content">
                <div class="app-screen-mobile-image"></div>
                <div class="apps-screenshort">
                  <div
                    id="carousel"
                    className="carousel-container"
                    style={{
                      transform: `translateZ(-467px) rotateY(${rotationAngle}deg)`,
                    }}
                  >
                    <figure
                      className="carousel-figure"
                      style={{
                        opacity: 1,
                        transform: "rotateY(0deg) translateZ(467px)",
                      }}
                    >
                      <img src={appSlideImg2} alt="" />
                    </figure>
                    <figure
                      className="carousel-figure"
                      style={{
                        opacity: 1,
                        transform: "rotateY(30deg) translateZ(467px)",
                      }}
                    >
                      <img src={appSlideImg1} alt="" />
                    </figure>
                    <figure
                      className="carousel-figure"
                      style={{
                        opacity: 1,
                        transform: "rotateY(60deg) translateZ(467px)",
                      }}
                    >
                      <img src={appSlideImg4} alt="" />
                    </figure>
                    <figure
                      className="carousel-figure"
                      style={{
                        opacity: 1,
                        transform: "rotateY(90deg) translateZ(467px)",
                      }}
                    >
                      <img src={appSlideImg3} alt="" />
                    </figure>
                    <figure
                      className="carousel-figure"
                      style={{
                        opacity: 1,
                        transform: "rotateY(120deg) translateZ(467px)",
                      }}
                    >
                      <img src={appSlideImg5} alt="" />
                    </figure>
                    <figure
                      className="carousel-figure"
                      style={{
                        opacity: 5,
                        transform: "rotateY(150deg) translateZ(467px)",
                      }}
                    >
                      <img src={appSlideImg3} alt="" />
                    </figure>
                    <figure
                      className="carousel-figure"
                      style={{
                        opacity: 4,
                        transform: "rotateY(180deg) translateZ(467px)",
                      }}
                    >
                      <img src={appSlideImg2} alt="" />
                    </figure>
                    <figure
                      style={{
                        opacity: 3,
                        transform: "rotateY(210deg) translateZ(467px)",
                      }}
                    >
                      <img src={appSlideImg1} alt="" />
                    </figure>
                    <figure
                      style={{
                        opacity: 2,
                        transform: "rotateY(240deg) translateZ(467px)",
                      }}
                    >
                      <img src={appSlideImg5} alt="" />
                    </figure>
                    <figure
                      style={{
                        opacity: 1,
                        transform: "rotateY(270deg) translateZ(467px)",
                      }}
                    >
                      <img src={appSlideImg4} alt="" />
                    </figure>
                    <figure
                      style={{
                        opacity: 1,
                        transform: "rotateY(300deg) translateZ(467px)",
                      }}
                    >
                      <img src={appSlideImg5} alt="" />
                    </figure>
                    <figure
                      style={{
                        opacity: 2,
                        transform: "rotateY(330deg) translateZ(467px)",
                      }}
                    >
                      <img src={appSlideImg3} alt="" />
                    </figure>
                  </div>
                </div>
                <div id="options">
                  <p id="navigation">
                    <button
                      id="previous"
                      data-increment="-1"
                      class="arrow-left"
                      onClick={() => handleChangeCarousel(-30)}
                    >
                      <InlineIcon icon={arrowLeftIcon} className="icon" />
                    </button>
                    <button
                      id="next"
                      data-increment="1"
                      class="arrow-right"
                      onClick={() => handleChangeCarousel(30)}
                    >
                      <InlineIcon icon={arrowRightIcon} className="icon" />
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Footer Section */}
        <div className="footer">
          <div className="container">
            <div className="row align-items-start">
              <div className="col-md-5">
                <img src={menuLogo} className="footer-logo" alt="" />
                <p className="copyrights">© 2024 Axtra, All rights reserved.</p>
              </div>
              <div className="col-md-7">
                <div className="footer-link-wrap d-flex justify-content-end mb-3">
                  <Link className="footer-link">
                    <span>About Us</span>
                  </Link>
                  <Link className="footer-link">
                    <span>Contact Us</span>
                  </Link>
                  <Link className="footer-link">
                    <span>Terms and Conditions</span>
                  </Link>
                  <Link className="footer-link">
                    <span>FAQs</span>
                  </Link>
                </div>
                <div className="d-flex justify-content-end">
                  <Link to={"/home"} className="social-img-wrap">
                    <InlineIcon icon={facebookIcon} />
                  </Link>
                  <Link to={"/home"} className="social-img-wrap">
                    <InlineIcon icon={googleIcon} />
                  </Link>
                  <Link to={"/home"} className="social-img-wrap">
                    <InlineIcon icon={instagramIcon} />
                  </Link>
                  <Link to={"/home"} className="social-img-wrap">
                    <InlineIcon icon={twitterIcon} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <OffCanvas
          isOpen={isOpen}
          onClose={close}
          labelledby="menu-button"
          height="100%"
          position="left"
          style={offCanvasMenuStyles}
        >
          <div className="menu-header" onClick={close}>
            <Link to={token ? "/home" : "/"} className="menu-header-logo">
              <img src={menuLogo} className="menu-logo" alt="Logo Image" />
            </Link>
          </div>

          <nav id="menu">
            <ul style={offCanvasMenuStyles.OffCanvasMenuList}>
              <li>
                <Link
                  to="/login"
                  className="offcanvas-menu_link d-flex flex-row"
                  onClick={close}
                >
                  <InlineIcon
                    icon={userOutlineIcon}
                    className="user-outline-icon mx-2 me-3"
                  />
                  <span>{languageData.loginOrSingUp}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/home"
                  className="offcanvas-menu_link d-flex flex-row"
                  onClick={close}
                >
                  <InlineIcon
                    icon={usersOutlineIcon}
                    className="icon mx-1 me-3"
                  />
                  <span>{languageData.partnerWithUs}</span>
                </Link>
              </li>
            </ul>
          </nav>
        </OffCanvas>
      </div>
    </>
  );
}
