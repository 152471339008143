import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

export function Button({
  label = "Submit",
  isLoad = false,
  isLoadHide,
  disabled = false,
  rightIcon = false,
  ...rest
}) {
  return (
    <button
      disabled={isLoad || disabled}
      style={isLoad || disabled ? { opacity: "0.65" } : null}
      {...rest}
    >
      {label}
      {isLoad && !isLoadHide && (
        <FontAwesomeIcon className="ms-2" icon={faSpinner} pulse />
      )}
      {rightIcon && (
        <FontAwesomeIcon className="ms-2" icon={faArrowRightLong} />
      )}
    </button>
  );
}
