// Services
import {
  getLocalStorageUserLocation,
  setLocalStorageUserLat,
  setLocalStorageUserLng,
  setLocalStorageUserLocation,
} from "../services/localStorage";
import { getDistance } from "geolib";
/// Config Data
import config from "../config.json";
import { Loader } from "@googlemaps/js-api-loader";
const { googleApiKey } = config;

getLocation();

export async function getLocation() {
  await new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          let userLat = parseFloat(position.coords.latitude);
          let userLng = parseFloat(position.coords.longitude);
          let userLocation = {
            lat: userLat,
            lng: userLng,
          };
          setLocalStorageUserLat(userLat);
          setLocalStorageUserLng(userLng);
          await setLocalStorageUserLocation(JSON.stringify(userLocation));
          resolve({
            lat: userLat,
            lng: userLng,
          });
        },
        (error) => {
          reject(error);
        }
      );
    } else {
      reject(new Error("Geolocation is not supported by this browser."));
    }
  });
}

export const getCurrentLocationInfo = async (
  currentLocation = getLocalStorageUserLocation(),
  addressInfo = {}
) => {
  // setCurrentCenter(currentLocation);
  const origin = new window.google.maps.LatLng(
    currentLocation.lat,
    currentLocation.lng
  );
  const geo = new window.google.maps.Geocoder();

  let tempData = { ...addressInfo };
  let storableLocation = {};

  await geo.geocode({ location: origin }, (results, status) => {
    if (status === "OK") {
      tempData.lat = currentLocation.lat;
      tempData.lng = currentLocation.lng;
      tempData.address = "";
      for (var ac = results[0].address_components.length - 1; ac >= 0; ac--) {
        var component = results[0].address_components[ac];

        if (component.types.includes("country")) {
          storableLocation.country = component.long_name;
          storableLocation.registered_country_iso_code = component.short_name;
        } else if (component.types.includes("administrative_area_level_1")) {
          storableLocation.state = component.short_name;
        } else if (
          component.types.includes("sublocality") ||
          component.types.includes("locality")
        ) {
          storableLocation.city = component.long_name;
        }
        if (
          component.types.includes("route") ||
          component.types.includes("transit_station")
        ) {
          storableLocation.route = component.long_name;
        }
      }
      tempData.address = `${
        storableLocation.route ? storableLocation.route + ", " : ""
      }${storableLocation.long_name ? storableLocation.long_name + ", " : ""}${
        storableLocation.city ? storableLocation.city + ", " : ""
      }${storableLocation.state ? storableLocation.state : ""}`;
    } else {
      console.log(
        "Geo code was not successful for the following reason: " + status
      );
    }
  });

  return { address: tempData, locations: storableLocation };

  // setData(tempData);
  // setStorableLocation(storableLocation);
};

export const getDistanceWithGeolib = async (start, end) => {
  let distance = await getDistance(
    {
      latitude: parseFloat(end.lat),
      longitude: parseFloat(end.lng),
    },
    {
      latitude: parseFloat(start.lat),
      longitude: parseFloat(start.lng),
    }
  );
  distance = distance / 1000;

  return distance;
};

export const calculateDistance = async (lat, lng, userLat, userLng) => {
  console.log(lat, lng, userLat, userLng);
  const loader = new Loader({
    apiKey: googleApiKey,
    version: "weekly",
    libraries: ["places"],
  });

  let currentDistance = 0;
  let time = 1;
  let googleApiError = false;

  try {
    const google = await loader.load();
    const service = new google.maps.DistanceMatrixService();
    const response = await new Promise((resolve, reject) => {
      service.getDistanceMatrix(
        {
          origins: [{ lat: parseFloat(lat), lng: parseFloat(lng) }],
          destinations: [
            { lat: parseFloat(userLat), lng: parseFloat(userLng) },
          ],
          travelMode:
            google.maps.TravelMode.DRIVING || google.maps.TravelMode.BICYCLING,
          unitSystem: google.maps.UnitSystem.METRIC,
        },
        (response, status) => {
          if (status === google.maps.DistanceMatrixStatus.OK) {
            resolve(response);
          } else {
            reject(status);
          }
        }
      );
    });
    const { distance, duration } = response.rows[0].elements[0];
    if (distance.value) {
      currentDistance = distance.value / 1000;
    }
    if (duration.value) {
      time = duration.value / 60;
    }
  } catch (error) {
    currentDistance = await getDistanceWithGeolib(
      { lat, lng },
      { lat: userLat, lng: userLng }
    );
    googleApiError = true;
  }

  return { distance: currentDistance, time, googleApiError };
};
