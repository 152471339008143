import http from "./httpService";
import config from "../config.json";

const { apiUrl } = config;

const apiEndPoint = apiUrl + "api/payment/";

export function getAllPayment() {
  return http.get(apiEndPoint + "getAllPaymentTypes");
}

export function getAllPaymentTypes(id) {
  return http.get(apiEndPoint + "getAllPaymentTypesByShop/" + id);
}

export function getPaymentQRCode(billNumber) {
  return http.get(apiEndPoint + "getOrderPaymentQRCode/" + billNumber);
}

export function getPosOrderPaymentQRCode(id) {
  return http.get(apiEndPoint + "getPosOrderPaymentQRCode/" + id);
}

export function completePayment(id) {
  return http.post(apiEndPoint + "completePayment/" + id);
}
