import React, { useEffect, useReducer, useRef, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import * as signalR from "@microsoft/signalr";
import ShakeEffect from "react-reveal/Tada";
import {
  osVersion,
  osName,
  fullBrowserVersion,
  browserName,
  deviceType,
} from "react-device-detect";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// CONTEXT
import { CommonContext } from "./context/CommonContext";
import { CartContext } from "./context/CartContext";
// CSS
import "./App.css";
import "react-notifications/lib/notifications.css";
import "bootstrap/dist/css/bootstrap.min.css";
// Services
import {
  getLocalStorageCartData,
  getLocalStorageLanguagePath,
  getLocalStorageShopId,
  getLocalStorageStockId,
  getLocalStorageUuid,
  setLocalStorageCartData,
  setLocalStorageShopTypes,
  setLocalStorageStockId,
  setLocalStorageUuid,
} from "./services/localStorage";
import {
  getAllLanguages,
  getLanguageData,
  setLanguage,
} from "./services/languageService";
import {
  getToken as getAuthToken,
  getUuid,
} from "./components/auth/authService";
import { countProductFromCart } from "./components/cart/cartService";
import { createOrEditMemberBrowserToken } from "./services/notificationService";
import { getFirebaseConfig, getVapidKey } from "./firebase-config";
import { getNotificationCount } from "./services/alertService";
// Components
import ProtectedRoute from "./components/auth/ProtectedRoute";
import { Loading } from "./components/common";
import Landing from "./components/home/landing";
// Assets Data
import noInternetIcon from "./assets/img/no-interent-icon.png";

import "./utils/baseName";
import "./utils/location";
import { playNotificationSound } from "./utils/soundHelper";

// Config data
import config from "./config.json";
import { getLocation } from "./utils/location";
const { baseRoute, notiApiUrl, webUrl } = config;

const initialState = {
  languageData: {},
  cartCount: 0,
  connection: null,
  socketId: null,
  uuid: null,
  currentToken: null,
  notiCount: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setLanguageData":
      return { ...state, languageData: action.payload };
    case "setCartCount":
      return { ...state, cartCount: action.payload };
    case "setConnection":
      return { ...state, connection: action.payload };
    case "setSocketId":
      return { ...state, socketId: action.payload };
    case "setUuid":
      return { ...state, uuid: action.payload };
    case "setCurrentToken":
      return { ...state, currentToken: action.payload };
    case "setNotiCount":
      return { ...state, notiCount: action.payload };
    default:
      throw new Error(`Unsupported action type ${action.type}`);
  }
};

const initialCartDataState = {
  cart: [],
};

const cartReducer = (state, action) => {
  switch (action.type) {
    case "setCartData":
      return { ...state, cart: action.payload };
    default:
      throw new Error(`Unsupported action type ${action.type}`);
  }
};

function App() {
  const [common, dispatch] = useReducer(reducer, initialState);
  const { languageData } = common;

  const [cart, cartDispatch] = useReducer(cartReducer, initialCartDataState);
  const [voucherCart, setVoucherCartData] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [uuid, setUuid] = useState("");
  const [isLoad, setIsLoad] = useState(true);
  const handlerRef = useRef(null);

  useEffect(() => {
    (async () => {
      await getLocation();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await loadLanguage();
      await loadCartData();
      const token = await getAuthToken();
      dispatch({ type: "setCurrentToken", payload: token });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (navigator.onLine) {
        await loadUuid();
        await loadNotiCount();
      }
      await setIsLoad(false);
    })();
  }, []);

  useEffect(() => {
    if (uuid && !common.connection) {
      const newConnection = new signalR.HubConnectionBuilder()
        .withUrl(notiApiUrl + "MemberNotification?uuid=" + uuid)
        .withAutomaticReconnect()
        .build();

      dispatch({ type: "setConnection", payload: newConnection });
      fireSetUp(uuid);
    }
  }, [uuid]);

  useEffect(() => {
    if (
      common.connection &&
      common.connection.state === signalR.HubConnectionState.Disconnected
    ) {
      common.connection
        .start()
        .catch((err) => console.error("Connection failed: ", err));

      common.connection.onreconnecting((error) => {
        console.log(`Connection lost due to error "${error}". Reconnecting.`);
      });

      common.connection.onclose((error) => {
        console.log(
          `Connection closed due to error "${error}". Try to start new connection.`
        );
        // Optionally, you can add a delay here to prevent too frequent attempts to connect
        setTimeout(startConnection, 5000);
      });

      common.connection.on("ReceiveNotification", (noti) => {
        console.log(noti);
        if (handlerRef.current) {
          handlerRef.current(noti);
        } else {
          NotificationManager.success(noti.body, noti.title, 8000, () => {
            window.location.href = noti.link;
          });
          playNotificationSound();
        }
        loadNotiCount();
      });

      return () => {
        handlerRef.current = null;
      };
    }
  }, [common.connection]);

  const startConnection = async () => {
    try {
      if (common.connection.state === signalR.HubConnectionState.Disconnected) {
        await common.connection.start();
      }
      console.log("SignalR Connected.", common.connection.connectionId);
    } catch (err) {
      console.log("Connection failed: ", err);
      // You could add a retry logic here
      setTimeout(startConnection, 5000);
    }
  };

  const isIOS = async () => {
    const browserInfo = navigator.userAgent.toLowerCase();
    if (browserInfo.match("iphone") || browserInfo.match("ipad")) {
      return "true";
    }
    if (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform)
    ) {
      return "true";
    }
    return "false";
  };

  const handleSetCurrentToken = (token) => {
    dispatch({ type: "setCurrentToken", payload: token });
  };

  const fireSetUp = async (uuid) => {
    const isItIOS = await isIOS();
    if (isItIOS == "true") return;
    Notification.requestPermission().then(async (permission) => {
      console.log(permission);
      if (permission === "granted") {
        const firebaseApp = initializeApp(getFirebaseConfig());
        const messaging = getMessaging(firebaseApp);

        navigator.serviceWorker
          .register(`${webUrl}firebase-messaging-sw.js`, {
            updateViaCache: "none",
          })
          .then((registration) => {
            // wait for the service worker installation to complete
            let serviceWorker;
            if (registration.installing) {
              serviceWorker = registration.installing;
              console.log("Service worker is installing...");
            } else if (registration.waiting) {
              serviceWorker = registration.waiting;
              console.log("Service worker is installed and waiting...");
            } else if (registration.active) {
              serviceWorker = registration.active;
              console.log("Service worker is active.");
              getToken(messaging, {
                serviceWorkerRegistration: registration,
                vapidKey: getVapidKey(),
              })
                .then(async (currentToken) => {
                  console.log(currentToken);
                  //setToken(currentToken);
                  const postData = {
                    uuid: uuid,
                    deviceToken: currentToken,
                  };
                  await createOrEditMemberBrowserToken(postData);
                })
                .catch((e) => {
                  console.log("Error", e);
                });
            }
          })
          .catch((error) => {
            console.log("Service worker failed to register", error);
          });
      } else {
        console.log("Do not have permission");
      }
    });
  };

  const loadLanguage = async () => {
    try {
      let languagePath = await getLocalStorageLanguagePath();
      let tLanguages = [];

      const { data: languages } = await getAllLanguages();

      if (languages.data) {
        tLanguages = languages.data;
        await setLanguages(tLanguages);
      }

      if (languagePath == null) {
        if (tLanguages.length > 0) {
          languagePath = tLanguages[0].path;
        } else {
          languagePath = "eng";
        }

        await setLanguage(tLanguages[0]);
      }

      const { data: lData } = await getLanguageData(languagePath);

      if (lData.data) {
        const languageData = await JSON.parse(lData.data);

        dispatch({ type: "setLanguageData", payload: languageData });
        // await loadCartCount();
      }
    } catch (error) {
      console.log(error);
      NotificationManager.error(
        languageData.somethingWentWrong || "Something went wrong!",
        languageData.error
      );
    }
  };

  const loadCartCount = async (type) => {
    // try {
    //   if (token) {
    //     let shopId = 0;
    //     const localShopId = await getLocalStorageShopId();
    //     if (localShopId) {
    //       shopId = parseInt(localShopId);
    //     }
    //     const config = {
    //       params: {
    //         shopId: shopId,
    //         orderType: type || "",
    //       },
    //     };
    //     const { data } = await countProductFromCart(config);
    //     if (data.data >= 0) {
    //       dispatch({ type: "setCartCount", payload: data.data });
    //     }
    //   }
    // } catch (error) {
    //   NotificationManager.error(
    //     languageData.somethingWentWrong,
    //     languageData.error
    //   );
    // }
  };

  const loadUuid = async () => {
    let tUuid = await getLocalStorageUuid();
    let socketId = await getLocalStorageStockId();
    try {
      if (!tUuid) {
        const deviceModal = `${osName} ${osVersion} ${deviceType} ${browserName} @${fullBrowserVersion}`;

        const { data } = await getUuid(deviceModal);

        if (data.result) {
          tUuid = data.id;
          socketId = data.socketId;
          await setLocalStorageUuid(data.id);
          await setLocalStorageStockId(data.socketId);
        }
      }
      dispatch({ type: "setSocketId", payload: socketId });
      dispatch({ type: "setUuid", payload: tUuid });
      setUuid(tUuid);
    } catch (error) {
      console.log("uuid error ", error);
    }
  };

  const loadCartData = async () => {
    const tCartData = (await getLocalStorageCartData()) || [];
    let tCount = 0;
    for (let cart of tCartData) {
      for (let product of cart.cartProducts) {
        if (!product.isDiscountProduct) {
          tCount += product.selectedQuantity;
        }
      }
    }
    dispatch({ type: "setCartCount", payload: tCount });
    cartDispatch({ type: "setCartData", payload: tCartData });
  };

  const changeCartData = async (sData, shopId, shopTypes = null, shopData) => {
    try {
      const localCartData = (await getLocalStorageCartData()) || [];
      let otherCartItems = await localCartData.filter(
        (c) => c.shopId != shopId
      );
      let finalCartData = otherCartItems;
      if (sData && sData.length > 0) {
        let findCartData = await localCartData.find((c) => c.shopId == shopId);
        if (findCartData) {
          findCartData.cartProducts = sData;
        } else {
          findCartData = {
            shopId: shopId,
            cartProducts: sData,
            shopTypes: shopTypes,
            shopData: shopData,
          };
        }
        finalCartData.push(findCartData);
      }
      let cartCount = 0;
      for (let cart of finalCartData) {
        for (let product of cart.cartProducts) {
          if (!product.isDiscountProduct) {
            cartCount += product.selectedQuantity;
          }
        }
      }
      dispatch({ type: "setCartCount", payload: cartCount });
      await setLocalStorageCartData(finalCartData);
      cartDispatch({ type: "setCartData", payload: finalCartData });
    } catch (ex) {
      console.log("Change cart error is ", ex);
    }

    // try {
    //   if (sData && sData.length > 0) {
    //     const currentCartData = await getLocalStorageCartData();
    //     if (currentCartData) {
    //       const findOldShopData = await currentCartData.filter(
    //         (item) => item.shopId === shopId
    //       );
    //       const findCurrentShopData = await sData.filter(
    //         (item) => item.shopId === shopId
    //       );
    //       if (
    //         JSON.stringify(findOldShopData) !==
    //         JSON.stringify(findCurrentShopData)
    //       ) {
    //         cartDispatch({ type: "setCartData", payload: findCurrentShopData });
    //         let tCount = 0;
    //         await findCurrentShopData.map((item) => {
    //           if (
    //             item.productData?.orderType !== "pos" &&
    //             !item.isDiscountProduct
    //           ) {
    //             tCount += item.selectedQuantity;
    //           }
    //         });
    //         dispatch({ type: "setCartCount", payload: tCount });
    //         setLocalStorageCartData(findCurrentShopData);
    //       }
    //     } else {
    //       const findCurrentShopData = await sData.filter(
    //         (item) => item.shopId === shopId
    //       );
    //       cartDispatch({ type: "setCartData", payload: findCurrentShopData });
    //       let tCount = 0;
    //       await findCurrentShopData.map((item) => {
    //         if (
    //           item.productData?.orderType !== "pos" &&
    //           !item.isDiscountProduct
    //         ) {
    //           tCount += item.selectedQuantity;
    //         }
    //       });
    //       dispatch({ type: "setCartCount", payload: tCount });
    //       setLocalStorageCartData(findCurrentShopData);
    //     }
    //   } else {
    //     dispatch({ type: "setCartCount", payload: 0 });
    //     cartDispatch({ type: "setCartData", payload: [] });
    //     setLocalStorageCartData([]);
    //   }
    // } catch (ex) {
    //   console.log("Change cart error is ", ex);
    // }
  };

  const changeVoucherCartData = async (sData, currentShopId) => {
    try {
      const localCartData = voucherCart || [];
      let findCurrentShopData = [];
      let cartData = {
        vouchers: sData,
        shopId: currentShopId,
      };
      let otherCartItems = await localCartData.filter(
        (c) => c.shopId != currentShopId
      );
      otherCartItems.push(cartData);
      setVoucherCartData(otherCartItems);
      return true;
    } catch (ex) {
      console.log("Change cart error is ", ex);
      return false;
    }
  };

  const loadNotiCount = async () => {
    try {
      const { data } = await getNotificationCount();

      if (data.result) {
        dispatch({ type: "setNotiCount", payload: data.totalAlerts });
      }
    } catch (error) {
      console.log("noti count error ", error);
    }
  };

  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse" />
    </div>
  );

  const contextValue = {
    ...common,
    handlerRef,
    dispatch,
    loadCartCount,
    loadNotiCount,
    handleSetCurrentToken,
  };

  const CartContextValue = {
    ...cart,
    cartDispatch,
    changeCartData,
    voucherCart,
    changeVoucherCartData,
  };

  if (isLoad) return <Loading isLoad={isLoad} />;
  else if (!navigator.onLine)
    return (
      <div className="no-internet-content">
        <ShakeEffect>
          <img
            src={noInternetIcon}
            className="no-internet-icon"
            alt="No Internet Connection"
          />
        </ShakeEffect>
        <div className="text-muted">
          <b>
            {languageData.noInternetConnection ||
              "No internet connection found"}
          </b>
        </div>
        <div className="text-muted">
          <small>
            {languageData.plsCheckYourInternet ||
              "Please check your internet connection and try again!"}
          </small>
        </div>
      </div>
    );
  return (
    <CommonContext.Provider value={contextValue}>
      <CartContext.Provider value={CartContextValue}>
        <NotificationContainer />
        <BrowserRouter basename={baseRoute} forceRefresh={true}>
          <React.Suspense fallback={loading}>
            <Routes>
              <Route path={"/"} element={<Landing />} />
              <Route
                path="/*"
                element={
                  <ProtectedRoute common={common} languages={languages} />
                }
              />
            </Routes>
          </React.Suspense>
        </BrowserRouter>
      </CartContext.Provider>
    </CommonContext.Provider>
  );
}

export default App;
