import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonContext from "../../context/CommonContext";
import { NotificationManager } from "react-notifications";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { InlineIcon } from "@iconify/react";
import TicketIcon from "@iconify/icons-mdi/ticket-percent";
import StoreAlert from "@iconify/icons-mdi/store-alert";
/// Services
import {
  addToFavouriteShop,
  getShopByName,
  getShopReviewByShopId,
  removeFromFavouriteShop,
  subscribe,
} from "../shop/shopService";
import { setLocalStorageUserPrevRoute } from "../../services/localStorage";
/// Components
import { Image, Button } from "../common";
/// Assets Data
import WarningImg from "../../assets/img/warning.png";
import IconPromo from "../../assets/img/icon-promo.png";
/// Config Data
import config from "../../config.json";
import { getProductById } from "./productService";
import {
  calculatePromotionAmount,
  calcualteServiceAndTaxAmount,
} from "../../services/orderService";
import _ from "lodash";

const { mainUrl, baseRoute, yPercentOff, xBuyYPercentOff, CURRENCY_CODE } =
  config;

export const handleShopFavourite = async (shopId, favourite, languageData) => {
  try {
    let response = {};
    if (!favourite) {
      const { data: response } = await addToFavouriteShop(shopId);
    } else {
      const { data: response } = await removeFromFavouriteShop(shopId);
    }
  } catch (error) {
    NotificationManager.error("favourite", languageData.serverError);
  }
};

export const LoginModal = ({
  isPos = false,
  isShow = false,
  setIsShow,
  shopRoute,
  table = 0,
}) => {
  const navigate = useNavigate();

  const commonContext = useContext(CommonContext);
  const { languageData } = commonContext;

  let localStorageRoute = `/productList/${shopRoute}`;
  if (isPos) {
    localStorageRoute = `/pos/productList/${shopRoute}/${table}`;
  }

  const toggle = () => {
    setIsShow(!isShow);
  };

  return (
    <Modal
      isOpen={isShow}
      toggle={toggle}
      className="modal-dialog-centered social-modal"
      id="modal-dialog"
    >
      <ModalBody>
        <div className="d-flex justify-content-center align-items-center mb-3">
          <img src={WarningImg} className="modal-icon" alt="warning image" />
        </div>
        <p className="modal-text mb-3 text-center">
          {languageData.nowLoginMessage}
        </p>
        <div className="text-center">
          <button
            className="btn btn-block btn-sm btn-primary me-2 px-3"
            onClick={() => {
              setLocalStorageUserPrevRoute(localStorageRoute);
              navigate("/login");
              toggle();
            }}
          >
            {languageData.yes}
          </button>
          <button
            className="btn btn-block btn-sm btn-secondary px-3"
            onClick={() => {
              toggle();
            }}
          >
            {languageData.no}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export const SubscribeModal = ({
  isShow = false,
  setIsShow,
  shopId,
  loadShop,
}) => {
  const commonContext = useContext(CommonContext);
  const { languageData } = commonContext;

  const [isLoad, setIsLoad] = useState(false);

  const toggle = () => {
    setIsShow(!isShow);
  };

  const doSubmit = async () => {
    setIsLoad(true);
    try {
      const { data } = await subscribe(shopId);

      if (data.result) {
        NotificationManager.success(null, languageData.success);
        loadShop();
      } else {
        NotificationManager.error(data.message, languageData.error);
      }

      setIsLoad(false);
      toggle();
    } catch (error) {
      NotificationManager.error("subscribe", languageData.serverError);
    }
  };

  return (
    <Modal
      isOpen={isShow}
      toggle={toggle}
      className="modal-dialog-centered custom-modal"
    >
      <ModalBody>
        <p className="modal-text text-center">
          {languageData.areUSureToSubscribe}
        </p>
        <div className="d-flex justify-content-center">
          <Button
            className="btn btn-primary "
            style={{ marginRight: 10 }}
            label={languageData.yesIAmSure}
            isLoad={isLoad}
            onClick={doSubmit}
          />
          <Button
            className="btn btn-secondary"
            label={languageData.cancel}
            disabled={isLoad}
            onClick={toggle}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export const handleShare = async (route, languageData) => {
  if (navigator.share) {
    navigator
      .share({
        title: "AxtraPos",
        url: `${mainUrl + baseRoute}/productList/${route}`,
      })
      .then(() => {
        console.log("Thanks for sharing!");
      })
      .catch((err) => {
        console.log("Error while using Web share API: ", err);
      });
  } else {
    NotificationManager.warning(
      "Browser doesn't support this API !",
      languageData.error
    );
  }
};

export const PromotionModal = ({
  modalInfo: { isShow = false, bannerImage, title = null, description = null },
  setModalInfo,
  initialModalInfo,
  onApply,
}) => {
  const commonContext = useContext(CommonContext);
  const { languageData } = commonContext;

  const toggle = () => {
    if (isShow) {
      setModalInfo(initialModalInfo);
    }
  };

  return (
    <Modal
      isOpen={isShow}
      toggle={toggle}
      className="modal-dialog-centered discount-detail-modal"
    >
      <ModalHeader className="d-flex justify-content-between">
        <span>{languageData.discountDetail}</span>
        <FontAwesomeIcon
          icon={faClose}
          onClick={() => toggle()}
          className="close-icon"
        />
      </ModalHeader>

      <ModalBody>
        <div className="promotion-modal-img">
          <Image name={bannerImage} alt="Banner Image" className="img-fluid" />
        </div>
        <div className="d-flex flex-row mb-2">
          <div>
            <img src={IconPromo} className="promo-img" />
          </div>
          <p className="discount-title">{title}</p>
        </div>
        <div className="discount-description-wrapper">
          <p className="discount-title mb-1">{languageData.description}</p>
          <p className="discount-description">{description}</p>
        </div>
        <Button
          label={languageData.apply}
          className="btn btn-primary w-100 mt-3"
          onClick={() => {
            onApply();
            toggle();
          }}
        />
      </ModalBody>
    </Modal>
  );
};

export const VoucherModal = ({ isShow = false, setIsShow, data }) => {
  const commonContext = useContext(CommonContext);
  const { languageData } = commonContext;

  const toggle = () => {
    setIsShow(!isShow);
  };

  if (isShow)
    return (
      <Modal
        isOpen={isShow}
        toggle={toggle}
        className="modal-dialog-centered discount-detail-modal"
      >
        <ModalHeader className="d-flex justify-content-between">
          <span> {languageData.voucherDetail}</span>
          <FontAwesomeIcon
            icon={faClose}
            onClick={() => toggle()}
            className="close-icon"
          />
        </ModalHeader>

        <ModalBody>
          <div className="d-flex align-items-center mb-2">
            <div>
              <InlineIcon
                icon={TicketIcon}
                className="icon"
                style={{
                  fontSize: "22px",
                  marginRight: "10px",
                  color: "#217cdc",
                }}
              />
            </div>
            <div className="discount-text-wrap">
              <span className="discount-title">
                {languageData.spent} {data.qualifyingAmount}{" "}
                {languageData[CURRENCY_CODE]} {languageData.andGet}{" "}
                {data.voucherAmount} {languageData[CURRENCY_CODE]}
              </span>
            </div>
          </div>
          <div className="discount-description-wrapper">
            <p className="label-small">Description</p>
            <p className="discount-description">{data.description}</p>
          </div>
        </ModalBody>
      </Modal>
    );
};

export const RenderShopCloseModal = ({ isShow = false }) => {
  const navigate = useNavigate();

  const commonContext = useContext(CommonContext);
  const { languageData } = commonContext;

  return (
    <Modal
      isOpen={isShow}
      className="modal-dialog-centered cart-item-modal"
      id="modal-dialog"
      sm="sm"
    >
      <ModalBody>
        <div className="d-flex justify-content-center align-items-center mb-3">
          <InlineIcon icon={StoreAlert} className="shop-close-icon" />
        </div>
        <p className="modal-text text-center mb-0">
          {languageData.sorryThisShopIsCloseNow}
        </p>
        <p className="modal-sub-text text-center mb-3">
          {languageData.plsFindOtherOpeningShopToBuy}
        </p>
        <div className="d-flex justify-content-center mt-1">
          <Button
            label={languageData.backToHome}
            className="btn btn-sm btn-primary"
            onClick={() => navigate("/home")}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export const loadShopReviews = async (id) => {
  try {
    const { data } = await getShopReviewByShopId(id);

    let fiveStarCount = 0;
    let fourStarCount = 0;
    let threeStarCount = 0;
    let twoStarCount = 0;
    let oneStarCount = 0;
    let tRating = 0;

    if (data.data.length > 0) {
      data?.data.map((item) => {
        if (item.rating === 5) {
          fiveStarCount += 1;
        }
        if (item.rating === 4) {
          fourStarCount += 1;
        }
        if (item.rating === 3) {
          threeStarCount += 1;
        }
        if (item.rating === 2) {
          twoStarCount += 1;
        }
        if (item.rating === 1) {
          oneStarCount += 1;
        }
      });
    }
    let oneTotal = oneStarCount * 1;
    let twoTotal = twoStarCount * 2;
    let threeTotal = threeStarCount * 3;
    let fourTotal = fourStarCount * 4;
    let fiveTotal = fiveStarCount * 5;

    let totalClicks =
      oneStarCount +
      twoStarCount +
      threeStarCount +
      fourStarCount +
      fiveStarCount;
    let totalStars = oneTotal + twoTotal + threeTotal + fourTotal + fiveTotal;
    tRating = totalStars / totalClicks;
    tRating = tRating || 0;
    if (tRating > 0) {
      tRating = tRating.toPrecision(2);
    }

    // setRating(tRating);
    // setRatingCount(data.reviewCount);
    return { result: true, rating: tRating, count: data.reviewCount };
  } catch (error) {
    console.log(error);
  }
};

export const loadProduct = async (item) => {
  try {
    const config = {
      params: {
        id: item.productData?.id || item.id,
        shopId: item.productData?.shopId || item.shopId,
      },
    };
    const { data } = await getProductById(config);

    if (data.result) {
      let product = { ...data.data, ...data.shopTypes };
      product.oPrice = item.price;
      product.oSalePrice = item.salePrice;
      product.quantity = 1;
      let customProductSelect = 0;

      // For Attributes
      product.attributes = item.attributes;
      if (product.isShop) {
        let selectedAttributeOptions = [];

        product.attributes?.map((att) => {
          let attId = att.id;
          att.options?.map((opt) => {
            opt.isSelected = opt.isChecked;
          });

          let attributeOption = {
            attributeId: attId,
            attributeOptionId: att.options.find((o) => o.isSelected === true)
              .attributeOptionId,
          };
          selectedAttributeOptions.push(attributeOption);
        });

        product.attributeCombinations?.map((row) => {
          row.sortedXml = JSON.parse(row.attributesXml).sort(
            (a, b) => a.attributeId - b.attributeId
          );
        });

        const sortSelectedOptions = selectedAttributeOptions.sort(
          (a, b) => a.attributeId - b.attributeId
        );

        const findCombineRow = await product.attributeCombinations.find(
          (row) =>
            JSON.stringify(row.sortedXml) ===
            JSON.stringify(sortSelectedOptions)
        );

        if (findCombineRow) {
          product.maxQuantity = findCombineRow.maxQuantity;
          product.price += findCombineRow.priceAdjustment;
          product.salePrice += findCombineRow.priceAdjustment;
        }
      }

      /// For Product Combo Set
      product.isComboSet = data.data.type === 3 || data.data.type === 4;
      if (product.isComboSet) {
        product.hasSubMenu = false;
        let isSelectSubMenu = false;
        const defaultComboSet = await product.comboProducts.filter(
          (item) => item.isDefault
        );
        if (defaultComboSet.length > 0) {
          customProductSelect = defaultComboSet.length;
        }
        await Promise.all(
          product.comboProducts.map((combo) => {
            if (product.type == 3) {
              combo.isSelected = true;
            } else {
              combo.isSelected = combo.isDefault;
              /// For Sub-menu show
              if (!combo.isDefault) {
                product.hasSubMenu = true;
              }
              /// For Auto Select Sub-Menu
              // if (
              //   !combo.isDefault &&
              //   customProductSelect < product.maxProduct
              // ) {
              //   product.hasSubMenu = true;
              //   combo.isSelected = true;
              //   customProductSelect += 1;
              // }
            }

            // For attributes
            combo.attributes.map((attribute) => {
              if (attribute.options.length > 0) {
                // For all selected
                attribute.options?.map((option) => {
                  option.isSelected = option.isChecked;
                });
                // if (attribute.type === 1) {
                //   attribute.options[0].isSelected = true;
                // } else {
                //   attribute.options.map((option) => {
                //     option.isSelected = true;
                //   });
                // }
              }
            });
          })
        );
      }
      return product;
    }

    return false;
  } catch (error) {
    console.log(error);
    // NotificationManager.error(
    //   "load product failed",
    //   languageData.serverError
    // );
  }
};

export const discountPercentOff = (item, code, amount, benefitAmountY) => {
  try {
    item.discountPrice = 0;
    item.isDiscount = false;
    if (code === yPercentOff) {
      item.discountPrice = (benefitAmountY / 100) * item.productData.price;
      item.isDiscountItem = true;
    } else if (code === xBuyYPercentOff) {
      item.discountPrice = (amount / 100) * item.productData.price;
      item.isDiscountItem = true;
    }

    item.selectedPrice = item.productData.price;
    item.salePrice =
      item.productData.price * item.selectedQuantity - item.discountPrice;
    item.price = item.productData.price * item.selectedQuantity;

    return item;
  } catch (error) {
    console.log(error);
    return item;
  }
};

export const getCalculateServiceAndTax = async (
  shopId,
  orderType,
  sCartProducts,
  deliveryFee,
  discountAmount
) => {
  let returnServiceInfo = {
    serviceFee: 0,
    taxAmount: 0,
  };
  try {
    let localCartData = [...sCartProducts];
    let productList = [];

    await Promise.all(
      localCartData.map(async (item) => {
        if (!item.isDiscountProduct) {
          let product = {
            productId: item.productData?.id,
            quantity: item.selectedQuantity,
            combinationId: item.selectedCombinationId,
            orderType: item.orderType
              ? typeof item.orderType == "number"
                ? item.orderType
                : null
              : null,
          };
          productList.push(product);
        }
      })
    ).then(async () => {
      const config = {
        shopId: shopId,
        products: productList,
        orderType: orderType,
        deliveryFee: deliveryFee,
        discountAmount: discountAmount,
      };
      const { data } = await calcualteServiceAndTaxAmount(config);
      if (data.data) {
        returnServiceInfo.serviceFee = data.data.serviceFee;
        returnServiceInfo.taxAmount = data.data.taxAmount;
      }
    });
    return returnServiceInfo;
  } catch (ex) {
    console.log(ex);
  }
};

export const getCalculateDiscount = async (
  shopId,
  orderType,
  sCartProducts,
  giveAwayItems,
  deliveryFee,
  socketId,
  voucherAmount = 0
) => {
  const sTime = new Date();
  let discountInfo = {
    giveAwayDiscounts: [],
    otherDiscounts: [],
    serviceFee: 0,
    taxAmount: 0,
  };
  let filteredData = [];
  let filteredGiveAwayData = [];
  let isCartItemChanged = false;
  let cartData = _.cloneDeep(sCartProducts);
  try {
    let productList = [];
    for (let product of cartData) {
      // if (!product.isDiscountProduct) {
      let data = {
        productId: product.productData?.id,
        quantity: product.selectedQuantity,
        combinationId: product.selectedCombinationId,
        isGiveawayDiscountItem: false,
        isDiscountProduct: false,
        socketId: socketId,
        orderType: product.orderType
          ? typeof product.orderType == "number"
            ? product.orderType
            : 1
          : 1,
        selectedDiscountIds: [],
      };
      productList.push(data);
      filteredData.push(product);
    }

    for (let product of giveAwayItems) {
      let data = {
        discountId: product.discountId,
        productId: product.productData?.id,
        quantity: product.selectedQuantity,
        combinationId: product.selectedCombinationId,
        isGiveawayDiscountItem: true,
        isDiscountProduct: true,
        socketId: socketId,
        orderType: product.orderType
          ? typeof product.orderType == "number"
            ? product.orderType
            : 1
          : 1,
        selectedDiscountIds: [],
        pairedProducts: product.pairedProducts,
      };
      productList.push(data);
    }

    discountInfo.totalDiscountAmount = 0;
    const config = {
      shopId: shopId,
      products: productList,
      orderType: orderType,
      deliveryFee: deliveryFee,
      voucherAmount: voucherAmount,
    };
    const sAPiTime = new Date();
    const { data } = await calcualteServiceAndTaxAmount(config);
    const eApiTime = new Date();
    let discountData = data.data?.discount
      ? await _.cloneDeep(data.data?.discount)
      : null;
    discountInfo.serviceFee = data.data?.serviceFee ? data.data?.serviceFee : 0;
    discountInfo.taxAmount = data.data?.taxAmount ? data.data?.taxAmount : 0;

    if (discountData) {
      if (discountData.isDiscount) {
        for (let discount of discountData.discounts) {
          if (discount.amount > 0) {
            discountInfo.totalDiscountAmount += discount.amount;
          }
          if (discount.yMaxQuantity > 0 && discount.productY?.length > 0) {
            let currentDiscountSelectedGiveAwayItems = giveAwayItems.filter(
              (item) =>
                item.isDiscountProduct &&
                item.discountId === discount.discountId
            );

            let discountYMaxQuantity = discount.yMaxQuantity;
            for (let item of discount.selectedGiveawayItems) {
              let checkItem = currentDiscountSelectedGiveAwayItems.find(
                (g) =>
                  g.productData.id === item.productId &&
                  _.isEqual(g.selectedCombinationId, item.combinationId)
              );
              if (checkItem) {
                for (let pair of checkItem.pairedProducts) {
                  let checkPair = discount.pairedProducts.find(
                    (p) => p.pairId === pair.pairId
                  );
                  if (checkPair) {
                    pair.products = checkPair.products;
                    checkPair.isComplete = true;
                  }
                }
                discountYMaxQuantity -= item.quantity;
                checkItem.selectedQuantity = item.quantity;
                filteredGiveAwayData.push(checkItem);
              }
            }
            if (discountYMaxQuantity > 0) {
              /// Fir give away product
              let giveAwayDiscount = {
                productIds: [],
                discountId: discount.discountId,
                maxQuantity: discountYMaxQuantity,
                discountProducts: [],
                customSelectedQuantity: 0,
                pairedProducts: [],
                title: discount.title,
              };

              let csq = 0;
              let q = discountYMaxQuantity;

              for (let item of discount.productX) {
                giveAwayDiscount.productIds.push(item);
              }

              for (let item of discount.pairedProducts) {
                let paired = { ...item };

                giveAwayDiscount.pairedProducts.push(paired);
              }

              for (let item of discount.productY) {
                let product = { ...item };
                product.isSelected = false;
                product.discountId = discount.discountId;
                product.productIds = discount.productX;
                if (csq < q) {
                  /// For customer discount product
                  product.isSelected = true;
                  csq += 1;
                  product.quantity = 1;
                }
                /// For Attributes
                for (let attribute of product.attributes) {
                  for (let option of attribute.options) {
                    option.isSelected = false;
                  }
                  if (
                    attribute.options.length > 0 &&
                    (attribute.type === 1 ||
                      attribute.type === 2 ||
                      attribute.type === 3)
                  ) {
                    attribute.options[0].isSelected = true;
                  }
                }

                /// For Combo Set Product Discount Product
                if (product.type === 3 || product.type === 4) {
                  product.isComboSet = true;
                  let comboCount = 0;
                  /// do something
                  for (let com of product.comboProducts) {
                    com.isSelected = false;
                    if (product.type == 3) {
                      com.isSelected = true;
                      comboCount += 1;
                    }
                    for (let attribute of com.attributes) {
                      if (attribute.options.length > 0) {
                        if (attribute.type === 1 || attribute.type === 3) {
                          attribute.options[0].isSelected = true;
                        } else {
                          for (let opt of attribute.options) {
                            opt.isSelected = true;
                          }
                        }
                      }
                    }
                  }
                }
                giveAwayDiscount.discountProducts.push(product);
              }
              discountInfo.giveAwayDiscounts.push(giveAwayDiscount);
            }
          } else {
            let isDiscountByQuantity = discount.code === yPercentOff;
            discountInfo.otherDiscounts.push({
              ...discount,
              isDiscountByQuantity,
              isPercent: discount.code.includes("PercentOff"),
            });
          }
        }
      }
    }
    discountInfo.cartData = filteredData;
    discountInfo.giveAwayItems = filteredGiveAwayData;
    const eTime = new Date();
    return discountInfo;
  } catch (error) {
    discountInfo.cartData = filteredData;
    discountInfo.giveAwayItems = filteredGiveAwayData;
    const eTime = new Date();
    return discountInfo;
    NotificationManager.error("calculate discount failed", "Error");
  }
};
